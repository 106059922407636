/* eslint-disable */
import React from "react";
import "./Maincontainer.css";

function Header() {
  return (
    <>
      <div className="header">
        <div className="header__container">
          <div className="cs-logo">
            <img
              height="32"
              src="/logos/content_symphony_white.png"
              alt="logo"
            />
          </div>
        </div>
      </div>
      <div className="maincontainer_heading">
        <p>Important notice</p>
      </div>
      <div className="maincontainer_message">
        <p>
          The Content Symphony Launch Portal, Marketplace platform and Content Library have now been deactivated.
          <br />
          <br />
          What to do now?
          <br />
          <br />
          If you need to access to any of the apps that appeared in the Launch
          Portal, you can access these via
          <br />
          MyApps if you are set up as a user.
          <br />
          <br />
          For support with production briefs that would have gone to a studio
          via the Marketplace, you can brief the
          <br />
          team at Tag via the{" "}
          <a
            href="https://forms.office.com/pages/responsepage.aspx?id=7JKJbtV2pU6OrrDF5Vh0mn8DOItcCoFBifMh3SJvlapUODRFRDRQQlBZWldXOUwzT1dDRlFZSkxaVi4u"
            target="_blank"
            className="maincontainer_engagement_link"
          >
            Engagement Form.
          </a>
          <br />
          <br />
          Please note, unless you have been briefed otherwise, your client specific instances remain available to you.
        </p>
      </div>
    </>
  );
}

export default Header;
