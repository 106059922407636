import React, { Component } from 'react';  
import { Switch, Route, withRouter } from 'react-router-dom';  
import Maincontainer from "../src/Maincontainer/Maincontainer"
    
class App extends Component {  
  render() {  
    return (  
      <Switch>  
        <Route exact path="/" component={Maincontainer} />  
        <Route path="/content-library" component={Maincontainer} />  
        <Route path="/launch-portal" component={Maincontainer} />  
      </Switch>  
    );  
  }  
}  
  
export default withRouter(App);  
